:root {
  --primary: #0f7bff!important;
  --blue: #0f7bff!important;
}
.App {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
@keyframes fadeIn {
  from { opacity: 0; transform: translateX(20%) }
  to { opacity: 1; transform: translateX(0) }
}
@keyframes fadeInTodo {
  from { opacity: 0; margin-top: -100%; }
  to { opacity: .3; margin-top: 0; }
}

html,
body,
#root,
.App{
  height: 100%;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.signature {
  max-width: 200px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.mw-50 {
  min-width: 50%;
}
.toast {
  opacity: 0;
  transition: opacity .3s ease;
  animation: fadeIn 1 .3s ease-out;
}
.toast.open {
  opacity: 1;
}
.toasts {
  z-index: 9;
  position: absolute;
  top: 4.5em;
  right: 1em;
}
.mh-75 {
  max-height: 75px;
}
table.bankdonations tr.open:not(.header) td {
  display: table-cell;
}
table.bankdonations tr td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@keyframes loading {
  0% { opacity: .3; }
  50% { opacity: .4; }
  100% { opacity: .3; }
}
.todo {
  transition: opacity .3s ease;
}
.loading {
  animation: loading infinite 1s ease;
}
.deleting {
  animation: fadeInTodo reverse 1s ease;
  opacity: 0;
  margin-top: -100%;
}

#loadingfax {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
}
.table-hartzbreaker tr td:nth-child(2){
  width: 100%;
}
.user-selection-none{
  user-select: none
}
.aspect-ratio-a4 {
  padding-bottom: 139%;
}
.aspect-ratio-a4-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.no-wrap {
  white-space: nowrap;
}

.fixed-height-100{
  height: 100px
}

#participant-table .btn{
  width: 50px
}

#participant-table td {
  vertical-align: middle;
}

table.raffle td {
  padding-top: 0;
  padding-bottom: 0;
}

tr.winner td.action, tr.winner td.name, tr.winner td.email, tr.winner td.date{
  background-color: #17a2b8;
  color: #ffffff
}

tr.winner td a, tr.winner-all td a{
  color: #ffffff;
  display: block;
  width: 160px;
  line-height: 50px;
  text-decoration: none;
}

tr.winner td a{
  color: #ffffff;
  text-decoration: none;
}

tr.winner td.name:hover, tr.winner td.email:hover, tr.blocked td.name:hover, tr.blocked td.email:hover{
  background-color: #343a40;
  color: #ffffff;
  cursor: pointer;
}

tr.confirmed a, tr.blocked a{
  color: #000000;
  display: block;
  line-height: 50px;
  text-decoration: none;
}

tr.confirmed td a, tr.blocked td a{
  text-decoration: none;
}

tr.confirmed td.name:hover, tr.blocked td.name:hover, tr.confirmed td.email:hover, tr.blocked td.email:hover{
  background-color: #343a40;
  color: #ffffff;
  cursor: pointer;
}

tr.confirmed td.name:hover a, tr.blocked td.name:hover a, tr.confirmed td.email:hover a, tr.blocked td.email:hover a{
  color: #ffffff;
  cursor: pointer;
}

tr.winner-all td.name:hover, tr.winner-all td.email:hover, tr.confirmed td.name:hover, tr.blocked td.name:hover{
  background-color: #343a40;
  color: #ffffff
}

#participant-table tr.bg-info {
  color: #ffffff
}

#participant-table td {
  height: 50px
}

.winner td.center {
  text-align: center;
}

th.fixed-width{
  width: 160px;
}

th.winner-header {
  text-align: center;
  min-width: 100px;
}

td.name {
  width: 160px;
  max-width: 160px;
  overflow-x: hidden;
  white-space: nowrap;
}

td.email {
  width: 250px;
  max-width: 250px;
  overflow-x: hidden;
  white-space: nowrap;
}

td.date {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
  padding: 0 10px;
  overflow-x: hidden;
  white-space: nowrap;
}

td.status {
  width: 80px;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
}

td.buttonrow{
  width: 225px;
  min-width: 225px;
  max-width: 225px;
}

#prizeAmount{
  width: 75px
}